import {StackItem} from '../../interfaces/router/stackItem';
import paths from '../../config/paths';
import React from 'react';

const AfterLogin = React.lazy(() => import('../../screens/auth/afterLogin'));
/**
 * An array of after Login routes for a navigation stack.
 * @type {StackItem[]}
 */
const afterLoginRoutes: StackItem[] = [
  {
    path: paths.auth.afterLogin,
    component: AfterLogin,
  },
];

export default afterLoginRoutes;

import React, {useCallback, useEffect, useRef, useState} from 'react';
import {connect} from 'react-redux';
import {RootState} from '../../redux/store';
import styled, {css, keyframes, useTheme} from 'styled-components';
import {closeLanguagePickerModal} from './actions';
import TextInput from '../../components/textInput';
import {searchAndFilterLanguages} from '../../utils/language';
import {
  LanguageItemType,
  LanguagePickerModalProps,
} from '../../interfaces/connected-components/languagePickerModal';
import {tr} from '../../translation';
import {handleChange} from '../../utils/form';
import LanguageListItem from '../../components/languageListItem';
import {Theme} from '../../theme/lightTheme';
import Icon from '../../components/icon';
import MyPressable from '../../components/myPressable';

// Replace with window innerHeight for React.js
const screenHeight = window.innerHeight;
const modalHeight = screenHeight * 0.9;

export const languageListHeaderHeight = 252;
export const languageListItemHeight = 168;

const hide = keyframes`
    from {
        opacity: 1;
        transform:  translateY(0);
        box-shadow: 0 0 500px rgba(241, 241, 241, 0);
        scale: 1;
    }
    to {
        opacity: 0;
        transform:  translateY(100%);
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
        scale: 0.95;
    }
`;

const show = keyframes`
    from {
        opacity: 0;
        transform:  translateY(100%);
        box-shadow: 0 0 0 rgba(241, 241, 241, 0);
        scale: 0.95;
    }
    to {
        opacity: 1;
        transform:  translateY(0);
        box-shadow: 0 0 500px rgba(241, 241, 241, 0);
        scale: 1;
    }
`;

const Container = styled.div<{isVisible: boolean}>`
  display: ${({isVisible}) => (isVisible ? 'flex' : 'none')};
  flex: 1;
  width: ${({theme}) => theme.dimensions.wdp(100)}px;
  height: ${({theme}) => theme.dimensions.hdp(100)}px;
  pointer-events: ${({isVisible}) => (isVisible ? 'auto' : 'none')};
  opacity: ${({isVisible}) => (isVisible ? 1 : 0)};
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999999;
`;
const ContentContainer = styled.div<{isVisible: boolean}>`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 90%;
  background-color: ${({theme}) => theme.text.light + 'CC'};
  margin: auto auto;
  z-index: 999;
  overflow: hidden;
  ${({isVisible}) =>
    isVisible
      ? css`
          animation: ${show} 0.4s ease-in-out;
          opacity: 1;
        `
      : css`
          animation: ${hide} 0.4s ease-in-out;
          pointer-events: none;
          opacity: 0;
        `};
  max-width: ${({theme}) => (theme.isMobile ? '90%' : '50%')};

  background: linear-gradient(
    360deg,
    ${({theme}) => theme.secondary + '77'} -68.52%,
    rgba(0, 159, 156, 0.3) 122.02%
  );
  filter: drop-shadow(0px 4px 8px rgba(0, 0, 0, 0.2));
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(16px);
  border-radius: 26px;
`;
const Scrollable = styled.div`
  overflow-y: auto;
  display: inline-block;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 94%;
  height: 94%;
  padding: 1rem;
  margin: auto auto;
  flex-wrap: wrap;
`;

const ScrollableWrap = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;

const Overlay = styled.div<{isVisible: boolean}>`
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100vw;
  height: 150vh;
  z-index: 100;
  overflow: hidden;
  overflow-y: auto;
  transform: translate(-50%, -50%);
  background-color: ${({theme}) => theme.overlay};
  transition: opacity 0.2s ease-in-out;
  opacity: ${({isVisible}) => (isVisible ? 1 : 0)};
  ${({isVisible}) =>
    !isVisible &&
    `
      pointer-events:none;
    `};
`;

const BackIconContainer = styled(MyPressable)`
  background-color: ${({theme}) => theme.text.light + '22'};
  margin-left: ${({theme}) => theme.text.s8};
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({theme}) => theme.text.s8};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
`;

const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  padding: 1.7rem 5%;
  z-index: 999;
`;

const LanguagePickerModal = ({
  languagePickerModal,
}: LanguagePickerModalProps) => {
  const theme = useTheme();
  const {
    isVisible,
    onSelectionChange,
    currentValue,
    filteredLanguageCodes,
    withSearch,
  } = languagePickerModal || {};
  const [modalVisible, setModalVisible] = useState(false);
  const [search, setSearch] = useState('');
  const [languages, setLanguages] = useState<LanguageItemType[]>([]);
  const [searchLanguages, setSearchLanguages] = useState<LanguageItemType[]>(
    [],
  );
  const scrollableDivRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isVisible) {
      setModalVisible(true);
      if ((filteredLanguageCodes || [])?.length > 0) {
        initPicker(false);
      }
    } else {
      setTimeout(() => {
        setModalVisible(false);
        initPicker();
        scrollableDivRef?.current?.scrollTo(0, 0);
      }, 200);
    }
  }, [isVisible]);

  useEffect(() => {
    handleSearch();
  }, [search]);

  const initPicker = useCallback(
    (refresh: boolean = true) => {
      const results = searchAndFilterLanguages(
        '',
        refresh ? null : filteredLanguageCodes,
      );
      setSearchLanguages(results);
      setLanguages(results);
      setSearch('');
    },
    [filteredLanguageCodes],
  );

  const handleSearch = useCallback(() => {
    if (search.length === 0) {
      setSearchLanguages(languages);
      return;
    }
    const results = searchAndFilterLanguages(search, null, languages);
    setSearchLanguages(results);
  }, [search]);

  const submit = (item: LanguageItemType) => {
    onSelectionChange && onSelectionChange(item);
    closeLanguagePickerModal();
  };

  const _renderItem = (item: LanguageItemType) => (
    <LanguageListItem
      key={item.code}
      item={item}
      onPress={submit}
      currentValue={currentValue}
    />
  );

  const _renderHeader = useCallback(() => {
    const isSearch = (search || '').trim().length > 0;
    return (
      <HeaderContainer key={isVisible + ''}>
        {withSearch && (
          <TextInput
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder={tr('languagePickerModal.searchPlaceholder')}
            showRightIcon
            rightIconName={isSearch ? 'search-off' : 'search'}
            rightIconType={'MaterialIcons'}
            onRightIconPress={isSearch ? () => setSearch('') : undefined}
            containerStyle={{
              flex: 1,
              alignSelf: 'center',
            }}
          />
        )}
        <BackIconContainer onPress={closeLanguagePickerModal}>
          <Icon
            type={'SVG'}
            name={'close'}
            color={theme.text.light}
            size={theme.text.s8}
          />
        </BackIconContainer>
      </HeaderContainer>
    );
  }, [search, withSearch, isVisible]);

  return (
    <Container isVisible={modalVisible}>
      <Overlay
        onClick={() => closeLanguagePickerModal()}
        isVisible={modalVisible}
      />
      <ContentContainer isVisible={isVisible}>
        {_renderHeader()}
        <Scrollable ref={scrollableDivRef}>
          <ScrollableWrap>
            {(searchLanguages || []).map(_renderItem)}
          </ScrollableWrap>
        </Scrollable>
      </ContentContainer>
    </Container>
  );
};

const mapStateToProps = (state: RootState) => ({
  languagePickerModal: state.languagePickerModal,
});

export default connect(mapStateToProps)(LanguagePickerModal);

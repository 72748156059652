import http from '../http';
import {api} from '../api';
import {
  ConfigResponse,
  ForgotPasswordResponse,
  LoginForm,
  LoginResponse,
  UpdateUserBody,
  UpdateUserResponse,
} from '../../interfaces/api/auth/login';
import {
  RegisterForm,
  RegisterResponse,
} from '../../interfaces/api/auth/register';

export const auth = {
  login: (body: LoginForm): Promise<LoginResponse> =>
    new Promise((resolve, reject) => {
      http
        .post(api.auth.login, body)
        .then(res => resolve(res as LoginResponse))
        .catch(e => reject(e));
    }),
  register: (body: RegisterForm): Promise<LoginResponse> =>
    new Promise((resolve, reject) => {
      http
        .post(api.auth.register, body)
        .then(res => resolve(res as RegisterResponse))
        .catch(e => reject(e));
    }),
  forgotPassword: (email: string): Promise<ForgotPasswordResponse> =>
    new Promise((resolve, reject) => {
      http
        .post(api.auth.forgotPassword, {email}, {}, true)
        .then(res => resolve(res as ForgotPasswordResponse))
        .catch(e => reject(e));
    }),
  getConfig: (): Promise<ConfigResponse> =>
    new Promise((resolve, reject) => {
      http
        .get(api.auth.config)
        .then(res => resolve(res as ConfigResponse))
        .catch(e => reject(e));
    }),
  updateUser: (body: UpdateUserBody): Promise<UpdateUserResponse> =>
    new Promise((resolve, reject) => {
      http
        .put(api.auth.updateUser, body)
        .then(res => resolve(res as UpdateUserResponse))
        .catch(e => reject(e));
    }),
};

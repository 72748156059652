import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {RootState, store} from '../redux/store';
import {getLocalData} from '../utils/storage';
import {login} from '../screens/auth/login/reducer';
import {updateConfig} from '../screens/auth/login/actions';
import {updateAppFont} from '../screens/content/fontChanger/actions';
import {toggleDarkMode} from '../screens/content/settings/action';
import RouteSwitcher from './routeSwitcher';
import {keys} from '../api/keys';
import {BrowserRouter} from 'react-router-dom';
import {MainLayoutProvider} from './routeMainLayout/context';

/**
 * The main navigation component that handles routing and navigation between screens.
 * @constructor
 */
const AppNavigator = () => {
  /**
   * The current router state, which determines whether to show 'content' , 'firstLogin' or 'auth' routes.
   * @type {'content' | 'auth' | 'firstLogin' | ''}
   */
  const [router, setRouter] = useState<'auth' | 'content' | 'firstLogin' | ''>(
    '',
  );
  const userStatus = useSelector((state: RootState) => state.auth);

  useEffect(() => {
    initRouter();
    updateConfig();
    updateAppFont(undefined, true);
    toggleDarkMode({isInitialize: true});
  }, []);

  useEffect(() => {
    checkLogin();
  }, [userStatus]);

  /**
   * Initialize routes by checking the user's login status in local storage.
   */
  const initRouter = async () => {
    const currentUser = await getLocalData(keys.CURRENT_USER);
    if (currentUser && Object.keys(currentUser).length !== 0) {
      store.dispatch(login(currentUser));
    } else {
      store.dispatch(login(null));
    }
  };

  /**
   * Check user login status and set the correct routes ('content' or 'auth').
   */
  const checkLogin = async () => {
    if (userStatus && userStatus?.currentUser?.is_first_login) {
      setRouter('firstLogin');
    } else if (userStatus && userStatus?.currentUser?.token) {
      setRouter('content');
    } else if (
      userStatus?.currentUser === null ||
      userStatus?.currentUser?.token === null
    ) {
      setRouter('auth');
    }
    return '';
  };

  return (
    <MainLayoutProvider>
      <BrowserRouter>
        <RouteSwitcher router={router} />
      </BrowserRouter>
    </MainLayoutProvider>
  );
};

export default AppNavigator;

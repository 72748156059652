import {StackItem} from '../../interfaces/router/stackItem';
import paths from '../../config/paths';
import React from 'react';

const Login = React.lazy(() => import('../../screens/auth/login'));
const ForgetPassword = React.lazy(
  () => import('../../screens/auth/forgetPassword'),
);

/**
 * An array of auth routes for a navigation stack.
 * @type {StackItem[]}
 */
const authRoutes: StackItem[] = [
  {
    path: paths.auth.login,
    component: Login,
  },
  // {
  //   path: paths.auth.register,
  //   component: Register,
  // },
  {
    path: paths.auth.forgetPassword,
    component: ForgetPassword,
  },
];

export default authRoutes;

import {useEffect, useRef} from 'react';
import {useSelector} from 'react-redux';
import {RootState} from '../../redux/store';
import {showGlobalToast} from '../../connected-components/toast/actions';
import {deepLinkingTypes} from '../enums';
import {tr} from '../../translation';
import {useNavigate} from 'react-router-dom';

/**
 * Custom hook to handle deep linking.
 * This hook handles the URL processing internally.
 */
const useDeepLinking = (): void => {
  const userState = useSelector((state: RootState) => state.auth);
  const waitingLogin = useRef(null);
  const navigate = useNavigate();
  useEffect(() => {
    handleDeepLink();
  }, [userState?.currentUser?.token]);

  // Function to handle the deep link
  const handleDeepLink = () => {
    if (userState?.currentUser?.token) {
      if (waitingLogin.current !== window.location.href) {
        navigate(
          (waitingLogin.current || '').replace(window.location.origin, ''),
          {
            replace: true,
          },
        );
        waitingLogin.current = null;
        return;
      }
    } else {
      if (
        (window.location.pathname || '').replace('/', '') ===
        deepLinkingTypes.article
      ) {
        waitingLogin.current = window.location.href;
        if (
          userState?.currentUser === null ||
          userState?.currentUser?.token === null
        ) {
          showGlobalToast({message: tr('app.needLogin')});
        }
      }
    }
  };
};

export default useDeepLinking;

import {AxiosResponse} from 'axios';
import axiosInstance from './axiosInstance';
import {finishLoading} from '../connected-components/loading/actions';
import {showGlobalToast} from '../connected-components/toast/actions';
import {tr} from '../translation';
import {api} from './api';

const handleError = (err: any, hideGlobalErrorToast?: boolean) => {
  finishLoading();
  const message = err?.response?.data?.message
    ? err?.response?.data?.message
    : err?.response?.data?.error?.message
      ? err?.response?.data?.error?.message
      : tr('app.error');

  if (!hideGlobalErrorToast) {
    showGlobalToast({
      message,
      type: 'error',
    });
  }
};

const http = {
  get: (url: string, hideGlobalErrorToast?: boolean) =>
    new Promise((resolve, reject) => {
      axiosInstance
        .get(url)
        .then((res: AxiosResponse<any>) => {
          if (!res?.data?.ok) {
            handleError(res, hideGlobalErrorToast);
          }
          resolve(res?.data);
        })
        .catch(err => {
          handleError(err, hideGlobalErrorToast);
          reject(err);
        });
    }),
  post: (
    url: string,
    body: any,
    headers = {},
    hideGlobalErrorToast?: boolean,
  ) =>
    new Promise((resolve, reject) => {
      axiosInstance
        .post(url, body, {headers})
        .then(res => {
          if (!res?.data?.ok && !url.includes(api.translate.googleTranslate)) {
            handleError(res, hideGlobalErrorToast);
          }
          resolve(res?.data);
        })
        .catch(err => {
          handleError(err, hideGlobalErrorToast);
          reject(err);
        });
    }),
  put: (url: string, body: any, headers = {}, hideGlobalErrorToast?: boolean) =>
    new Promise((resolve, reject) => {
      axiosInstance
        .put(url, body, {headers})
        .then(res => {
          if (!res?.data?.ok) {
            handleError(res, hideGlobalErrorToast);
          }
          resolve(res?.data);
        })
        .catch(err => {
          handleError(err, hideGlobalErrorToast);
          reject(err);
        });
    }),
  delete: (url: string, hideGlobalErrorToast?: boolean) =>
    new Promise((resolve, reject) => {
      axiosInstance
        .delete(url)
        .then(res => {
          if (!res?.data?.ok) {
            handleError(res, hideGlobalErrorToast);
          }
          resolve(res?.data);
        })
        .catch(err => {
          handleError(err, hideGlobalErrorToast);
          reject(err);
        });
    }),
};

export default http;
